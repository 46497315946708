import { ApolloQueryResult } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import EventSelector from '@components/Event/EventSelector';
import FullLoader from '@components/FullLoader';
import { GetMicrositeDocument, GetMicrositeQuery } from '@graphql/hasura';
import { fetchSiteConfig, withApollo } from '@lib/apollo';
import { createApolloClient } from '@lib/apollo/createApolloClient';
import { GetStaticProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

const Index: NextPage<{ data: GetMicrositeQuery }> = () => {
    const router = useRouter();

    useEffect(() => {
        if (process.env.NEXT_PUBLIC_STARTPAGE_REDIRECT) {
            router.replace(process.env.NEXT_PUBLIC_STARTPAGE_REDIRECT);
        }
    });

    if (process.env.NEXT_PUBLIC_STARTPAGE_REDIRECT) {
        return <FullLoader />;
    }

    return (
        <Flex h="100vh" align="center" justify="center">
            <EventSelector />
        </Flex>
    );
};

export const getStaticProps: GetStaticProps = async () => {
    const apolloClient = createApolloClient({}, true);
    const { data }: ApolloQueryResult<GetMicrositeQuery> = await apolloClient.query({
        query: GetMicrositeDocument,
        variables: {
            id: process.env.NEXT_PUBLIC_MICROSITE_UID
        }
    });

    return {
        props: { data: data, site: await fetchSiteConfig() }, // will be passed to the page component as props

        // Next.js will attempt to re-generate the page:
        // - When a request comes in
        // - At most once every second
        revalidate: 60 // In seconds
    };
};

export default withApollo()(Index);
