import { Box } from '@chakra-ui/layout';
import { useGetMicrositeEventsQuery } from '@graphql/hasura';
import { formatDateDistanceToNow } from '@lib/localization/date';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import Select, { components, OptionProps } from 'react-select';

const EventSelector: FC = () => {
    const { data } = useGetMicrositeEventsQuery({
        variables: { id: process.env.NEXT_PUBLIC_MICROSITE_UID }
    });
    const [isRedirecting, setIsRedirecting] = useState(false);
    const router = useRouter();

    if (data?.microsites_by_pk) {
        const events = data.microsites_by_pk.events;

        const options: any = [];

        events.forEach((e) => {
            options.push({
                label: e.event.title,
                value: e.event.id
            });
        });

        const Option: FC<OptionProps<any, false, any>> = (props) => {
            const { data } = props;

            const e = events.find((e) => e.event.id === data.value);

            if (!e) {
                return <components.Option {...props} />;
            }

            return (
                <components.Option {...props}>
                    <Box fontWeight="bold" fontSize="sm" flexGrow={1}>
                        {e.event.title}
                        <br />
                        <Box
                            as="span"
                            fontWeight="normal"
                            fontSize="xs"
                            textAlign="right"
                            color="gray.500">
                            {formatDateDistanceToNow(e.event.start_time, router.locale)}
                        </Box>
                    </Box>
                </components.Option>
            );
        };

        return (
            <Box w="100% !important" maxW="600px">
                <Select
                    isLoading={isRedirecting}
                    options={options}
                    components={{ Option }}
                    placeholder="Webinare"
                    style={{ width: '100% !important' }}
                    onChange={(value, actionMeta) => {
                        if (actionMeta.action === 'select-option' && value) {
                            const e = events.find((e) => e.event.id === value.value);

                            if (e) {
                                router.push(`/events/${e.event.id}/livestream`, undefined, {
                                    locale: e.event.locale_code
                                });
                                setIsRedirecting(true);
                            }
                        }
                    }}
                />
            </Box>
        );
    }

    return null;
};

export default EventSelector;
